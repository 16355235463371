import * as React from 'react';
import {ChangeEvent, Ref} from 'react';
import {FormCheck, FormGroup} from 'react-bootstrap';

export type RadioProps = {
    alias: string;
    ariaLabel?: string;
    caption?: string;
    checked?: boolean;
    className?: string;
    disabled?: boolean;
    error?: string;
    e2e?: string;
    errorPosition?: `above` | `below`;
    inputClass?: string;
    label?: string | JSX.Element;
    labelClass?: string;
    labelGreenText?: string;
    name: string;
    noMargin?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    ref?: Ref<unknown> | null;
    subLabel?: string | JSX.Element;
    value?: string;
} & JSX.IntrinsicElements[`input`];

export const RadioComponent = (
    {
        alias,
        ariaLabel,
        caption,
        checked,
        className,
        defaultChecked,
        disabled,
        e2e,
        error = ``,
        errorPosition = `below`,
        inputClass,
        label,
        labelClass,
        labelGreenText,
        name,
        noMargin = false,
        onChange,
        onFocus,
        subLabel,
        value = ``,
    }: RadioProps,
    ref,
) => {
    return (
        <FormGroup
            className={`custom-control custom-radio checkbox-lg${className ? ' ' + className : ''}${noMargin ? ' !tw-mb-0' : ' !tw-mb-2'}`}
            controlId={name}
        >
            {errorPosition === `above` && error && <label className="form-label error ml-2 pl-1">{error}</label>}
            <FormCheck.Input
                aria-label={ariaLabel}
                checked={checked}
                className={`custom-control-input${error ? ' error' : ''}${inputClass ? ' ' + inputClass : ''}`}
                defaultChecked={defaultChecked}
                disabled={disabled}
                id={`${alias}_radio`}
                name={name}
                onChange={onChange}
                onFocus={onFocus}
                ref={ref}
                type="radio"
                value={value}
                data-e2e={e2e}
            />
            <FormCheck.Label
                className={`custom-control-label${labelClass ? ' ' + labelClass : ''}${subLabel ? ' center-radio' : ''}`}
                htmlFor={`${alias}_radio`}
            >
                {label ? label : `\u00A0`}
                {labelGreenText ? <span className="green-200"> {labelGreenText}</span> : ``}
                {subLabel && <div className="caption">{subLabel}</div>}
            </FormCheck.Label>
            {errorPosition === `below` && error && <label className="form-label error ml-2 pl-1">{error}</label>}
            {caption && <div className="ml-2 pl-1 caption">{caption}</div>}
        </FormGroup>
    );
};
export const Radio = React.forwardRef(RadioComponent);
