import * as React from 'react';
import {ReactNode} from 'react';

interface BlockHeaderProps {
    children: ReactNode;
    className?: string;
    id?: string;
    marginBottom?: boolean;
    marginTop?: boolean;
}

export const BlockHeader = ({children, className, id, marginBottom = true, marginTop = true}: BlockHeaderProps) => (
    <div
        className={`tw-flex tw-p-4 ${marginBottom ? 'tw-mb-4' : 'tw-mb-0'} tw-leading-[1.2] tw-items-center ${
            marginTop ? 'tw-mt-4' : 'tw-mt-0'
        } tw-bg-gray-100 tw-border-t-[5px] tw-border-gray-200 tw-text-xl${className ? ' ' + className : ''}`}
        id={id}
    >
        {children}
    </div>
);
